import React, { useState } from "react";
import { Avatar, Button, CssBaseline, Grid, Slider, TextField, Toolbar, Typography } from "@mui/material";
import { LocalHospitalOutlined, PetsOutlined, ReceiptOutlined } from "@mui/icons-material";
// eslint-disable-next-line
import { category, code } from "../types";

interface IProps {
  codes: { [index in category]: code[] };
  setCodes: (codes: { [index in category]: code[] }) => void;
}

const formatCode = (code: string) =>
  code
    .toUpperCase()
    .replace(/[^0-9a-z]/gi, "")
    .replace(/\s/g, "")
    .replace(/1/g, "I")
    .replace(/0/g, "O")
    .substring(0, 12)
    .match(/.{1,4}/g)
    ?.join(" ") || "";

export default function ({ codes, setCodes }: IProps) {
  const [category, setCategory] = useState<category>("rec");

  return (
    <>
      <CssBaseline />
      <Toolbar>
        <Avatar
          style={
            category === "rec"
              ? { margin: 8, cursor: "pointer", backgroundColor: "#ff0066", width: 56, height: 56 }
              : { margin: 8, cursor: "pointer", backgroundColor: "rgba(255, 0, 102, 0.4)" }
          }
          onClick={() => setCategory("rec")}
        >
          <ReceiptOutlined />
        </Avatar>
        <Avatar
          style={
            category === "med"
              ? { margin: 8, cursor: "pointer", backgroundColor: "#ff0066", width: 56, height: 56 }
              : { margin: 8, cursor: "pointer", backgroundColor: "rgba(255, 0, 102, 0.4)" }
          }
          onClick={() => setCategory("med")}
        >
          <LocalHospitalOutlined />
        </Avatar>
        <Avatar
          style={
            category === "vet"
              ? { margin: 8, cursor: "pointer", backgroundColor: "#ff0066", width: 56, height: 56 }
              : { margin: 8, cursor: "pointer", backgroundColor: "rgba(255, 0, 102, 0.4)" }
          }
          onClick={() => setCategory("vet")}
        >
          <PetsOutlined />
        </Avatar>
      </Toolbar>
      <Typography variant="h5" align="center">
        {category === "rec" ? (
          <>
            Rezervace eReceptů
            <br />
            <Typography variant="caption">léky na předpis</Typography>
          </>
        ) : category === "med" ? (
          "Rezervace volně prodejných produktů"
        ) : (
          <>
            Rezervace veterinárních přípravků
            <br />
            <Typography variant="caption">léky na veterinární předpis i volně prodejné produkty</Typography>
          </>
        )}
      </Typography>

      <br />
      <br />
      {codes[category].map((c, i) => (
        <Grid key={i} container spacing={3}>
          <Grid xs={8} item>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              label={category === "rec" ? "Kód eReceptu" : "Název prod., velikost balení, síla"}
              value={c.code}
              onChange={(e) => {
                const newCodes = [...codes[category]];
                newCodes[i] = { ...codes[category][i], code: formatCode(e.target.value) };
                const res = { ...codes };
                res[category] = newCodes;
                setCodes(res);
              }}
              autoFocus={i === 0}
            />
          </Grid>
          <Grid item xs={4}>
            {category === "rec" ? (
              <>
                <Typography gutterBottom variant="body2">
                  Množství <sup style={{ color: "red" }}>*</sup> {c.ammount}
                </Typography>
                <Slider
                  value={c.ammount}
                  min={1}
                  max={4}
                  onChange={(e, newVal) => {
                    const newCodes = [...codes[category]];
                    newCodes[i] = { ...codes[category][i], ammount: newVal as number };
                    const res = { ...codes };
                    res[category] = newCodes;
                    setCodes(res);
                  }}
                />
              </>
            ) : (
              <TextField
                margin="normal"
                required
                variant="standard"
                type="number"
                label="Množství"
                value={c.ammount}
                onChange={(e) => {
                  const newCodes = [...codes[category]];
                  newCodes[i] = { ...codes[category][i], ammount: parseInt(e.target.value) };
                  const res = { ...codes };
                  res[category] = newCodes;
                  setCodes(res);
                }}
              />
            )}
          </Grid>
        </Grid>
      ))}

      <div style={{ width: "100%" }}>
        <Button
          color="primary"
          style={{ float: "right" }}
          onClick={() => {
            const res = { ...codes };
            res[category] = [...codes[category], { code: "", ammount: 1 }];
            setCodes(res);
          }}
        >
          + Přidat {category === "rec" ? "erecept" : "produkt"}
        </Button>
      </div>
      {category === "rec" && (
        <>
          <Typography variant="caption">
            <sup style={{ color: "red" }}>*</sup>Platí pouze pro opakovací recepty, kdy je možné zvolit 1 až 4 balení přípravku,
            dle preskripce lékaře.
          </Typography>
          <br />
        </>
      )}
    </>
  );
}
