import { MenuItem, MenuList, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import useWindowDimensions from "../Hooks/GetWindowDimenstions";

interface IProps {
  onSelect: (res: { lat: number; lng: number }) => void;
}

export default function LocationSearchInput({ onSelect }: IProps) {
  const [address, setAddress] = useState("");

  const handleSelect = (address: string) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(onSelect);
    setAddress("");
  };

  const { width, height } = useWindowDimensions();

  return (
    <PlacesAutocomplete value={address} onChange={(value) => setAddress(value)} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div
          style={{ position: "absolute", zIndex: 2, width: width < height ? "100%" : "40%", top: 12, right: width < height ? 0 : "20%", textAlign: "right" }}
        >
          <TextField
            {...getInputProps({
              placeholder: "Vyhledat na mapě",
              style: { backgroundColor: "white" },
              fullWidth: true,
            })}
          />
          {suggestions.length > 0 && (
            <Paper>
              <MenuList>
                {suggestions.map((suggestion) => (
                  <MenuItem {...getSuggestionItemProps(suggestion, {})} key={suggestion.id}>
                    {suggestion.description}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
}
