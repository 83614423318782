import React from "react";
import ReactDOM from "react-dom";
import "./Styles/index.css";
import App from "./App";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider
      theme={createMuiTheme({
        palette: {
          primary: { main: "#00af00", contrastText: "#ffffff" },
          secondary: { main: "#ff0066", light: "rgba(255, 0, 102, 0.4)" },
        },
      })}
    >
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();
