import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Grid, TextField } from "@mui/material";
import { PickupPlace } from "../types";
import { ordersService } from "../Utils/ApiService";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { formatters } from "../Utils/Formaters";
import { reducers } from "../Utils/Reducers";

const emptyPlace = {
  approvedForDistribution: false,
  approvedForEshop: false,
  approvedForPrescription: false,
  openningHoursList: [
    {
      dayName: "Pondělí",
      dayOrder: 0,
    },
    {
      dayName: "Úterý",
      dayOrder: 1,
    },
    {
      dayName: "Středa",
      dayOrder: 2,
    },
    {
      dayName: "Čtvrtek",
      dayOrder: 3,
    },
    {
      dayName: "Pátek",
      dayOrder: 4,
    },
    {
      dayName: "Sobota",
      dayOrder: 5,
    },
    {
      dayName: "Neděle",
      dayOrder: 6,
    },
    {
      dayName: "Svátky",
      dayOrder: 7,
    },
  ],
} as PickupPlace;

export default function AddPickupPlaceModal() {
  const [open, setOpen] = useState(false);
  const [place, setPlace] = useState<PickupPlace>(emptyPlace);

  const changeOppeningHours = (dayIndex: number, value: string) => {
    const newOHs = [...place.openningHoursList];
    newOHs[dayIndex] = { ...newOHs[dayIndex], value: value };
    setPlace({ ...place, openningHoursList: newOHs });
  };

  const save = () => {
    ordersService.post("/infrastructure/pickupPlace", {}, place, {
      success: () => {
        alert(
          "Vaše lékárna byla úspěšně zaregistrována a čeká na schválení. Během několika následujících dnů ji schválníme a případně vás kontaktujeme."
        );
        setOpen(false);
        setPlace(emptyPlace);
      },
      error: () => alert("Při ukládání objednávky došlo k chybě, zkuste to prosím později."),
    });
  };

  const isValid =
    place.name &&
    place.street &&
    place.houseNum &&
    place.zip &&
    place.phone &&
    place.mail &&
    place.openningHoursList.map((oh) => (oh.value ? true : false)).reduce(reducers.or);

  return (
    <>
      <Typography
        align="left"
        color="primary"
        style={{ textDecoration: "underline", cursor: "pointer", width: "100%", fontSize: "0.75rem" }}
        onClick={() => setOpen(true)}
      >
        Zaregistrovat vlastní lékárnu
      </Typography>
      <Dialog open={open} maxWidth="lg" onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle>Zaregistrovat lékárnu do systému Magistr Martin | Rezervace eReceptu</DialogTitle>
        <DialogContent>
          <Typography align="justify">
            I Vaše lékárna může být v našem systému Magistr Martin | Rezervace eReceptu. Zaregistrujte se k nám a díky našemu
            systému si i Vaši pacienti budou moci pohodlně rezervovat eRecepty i ve Vaší lékárně. Na podporu služby Vám můžeme
            na rozdávání pro pacienty zaslat návrh letáčků s výhodami a postupem rezervace. Zároveň si Vaši lékárnu budou moci
            vybrat klienti online lékárny MagistrMartin.cz pro rezervaci a vyzvednutí svých léků na předpis. Spojme síly
            nezávislých rodinných lékáren. Registrace je po dohodě na spolupráci ZDARMA.
          </Typography>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Informace o lékárně</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Název lékárny"
                    value={place.name || ""}
                    onChange={(e) => setPlace({ ...place, name: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Ulice"
                    value={place.street || ""}
                    onChange={(e) => setPlace({ ...place, street: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="ČP"
                    value={place.houseNum || ""}
                    onChange={(e) => setPlace({ ...place, houseNum: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="PSČ"
                    value={place.zip || ""}
                    onChange={(e) => setPlace({ ...place, zip: formatters.ZIPFormater(e.target.value) })}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Město"
                    value={place.city || ""}
                    onChange={(e) => setPlace({ ...place, city: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PhoneInput
                    value={place.phone || ""}
                    onChange={(val) => setPlace({ ...place, phone: formatters.PhoneFormater(val) })}
                    country="cz"
                    preferredCountries={["cz"]}
                    placeholder="+420 000 000 000"
                    inputStyle={{ width: "100%" }}
                    containerStyle={{ paddingBottom: 12 }}
                    specialLabel="Telefon (vedený u SÚKLu)"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Email (vedený u SÚKLu)"
                    value={place.mail || ""}
                    onChange={(e) => setPlace({ ...place, mail: e.target.value })}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Otevírací doba</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Pondělí"
                    value={place.openningHoursList[0].value || ""}
                    onChange={(e) => changeOppeningHours(0, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Úterý"
                    value={place.openningHoursList[1].value || ""}
                    onChange={(e) => changeOppeningHours(1, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Středa"
                    value={place.openningHoursList[2].value || ""}
                    onChange={(e) => changeOppeningHours(2, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Čtvrtek"
                    value={place.openningHoursList[3].value || ""}
                    onChange={(e) => changeOppeningHours(3, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Pátek"
                    value={place.openningHoursList[4].value || ""}
                    onChange={(e) => changeOppeningHours(4, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Sobota"
                    value={place.openningHoursList[5].value || ""}
                    onChange={(e) => changeOppeningHours(5, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Neděle"
                    value={place.openningHoursList[6].value || ""}
                    onChange={(e) => changeOppeningHours(6, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    color="primary"
                    label="Svátky"
                    value={place.openningHoursList[7].value || ""}
                    onChange={(e) => changeOppeningHours(7, e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Zrušit registraci</Button>
          <Button onClick={save} color="primary" disabled={!isValid}>
            Zaregistrovat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
