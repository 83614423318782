import React, { useState } from "react";
import Header from "./Components/Header";
// import JsBarcode from "jsbarcode";
import "./Styles/App.css";
import { Button, Container, Grid, Typography } from "@mui/material";
import PersonalDetailsForm from "./Components/PersonalDetailsForm";
// eslint-disable-next-line
import { category, code, personalData, Reservation } from "./types";
import ProductsForm from "./Components/ProductsForm";
import ReservationSummary from "./Components/ReservationSummary";
import { ordersService } from "./Utils/ApiService";
import AddPickupPlaceModal from "./Components/AddPickupPlaceModal";
import ReservationCreatedConfirm from "./Components/ReservationCreatedConfirm";

const defaultCodes = {
  rec: [{ code: "", ammount: 1 }],
  med: [{ code: "", ammount: 1 }],
  vet: [{ code: "", ammount: 1 }],
};

function App() {
  const [codes, setCodes] = useState<{ [index in category]: code[] }>({ ...defaultCodes });
  const [personalData, setPersonalData] = useState<personalData>({
    name: "",
    email: "",
    phone: "",
    place: -1,
  });
  const [btnLoading, setBtnLoading] = useState(false);

  const resetState = () => {
    setCodes({ ...defaultCodes });
    setPersonalData({
      name: "",
      email: "",
      phone: "",
      place: -1,
    });
    setBtnLoading(false);
  };

  const submitForm = () => {
    const data: Reservation = {
      mail: personalData.email,
      name: personalData.name,
      pickupPlaceId: personalData.place,
      phone: personalData.phone,
      reservedProductsList: [
        ...codes.rec
          .filter((c) => c.code.length > 0)
          .map((c) => {
            return { name: c.code, amount: c.ammount, type: "prescription" };
          }),
        ...codes.med
          .filter((c) => c.code.length > 0)
          .map((c) => {
            return { name: c.code, amount: c.ammount, type: "medicament" };
          }),
        ...codes.vet
          .filter((c) => c.code.length > 0)
          .map((c) => {
            return { name: c.code, amount: c.ammount, type: "veterinary" };
          }),
      ],
    };
    ordersService.post("/noauth/reservation", {}, data, {
      success: () => {
        resetState();
        window.location.href = "/reservationCreated";
      },
      error: () => {
        resetState();
        alert("Při rezervaci došlo k chybě, zkuste to prosím později a případně nás kontaktujte.");
      },
    });
  };

  const validateForm = () => {
    return (
      personalData.name.length > 0 &&
      personalData.email.length > 0 &&
      personalData.phone.length > 0 &&
      personalData.place > 0 &&
      personalData.email.includes("@")
    );
  };

  return (
    <>
      <Header />
      {window.location.href.toLowerCase().includes("reservationcreated") ? (
        <ReservationCreatedConfirm />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12} md={6} lg={8}>
              <img src="/img/guide.jpg" alt="Application guide" style={{ width: "90%", marginLeft: "5%", marginTop: "64px" }} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Container component="main" maxWidth="xs">
                <div style={{ marginTop: 24, display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <ProductsForm codes={codes} setCodes={setCodes} />
                  <PersonalDetailsForm personalData={personalData} setPersonalData={setPersonalData} />
                  <ReservationSummary codes={codes} />
                  {!validateForm() && (
                    <>
                      <br />
                      <Typography variant="caption" align="left" style={{ width: "100%" }}>
                        Je nutné vyplnit všechny údaje včetně výběru lékárny.
                      </Typography>
                    </>
                  )}
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={btnLoading || !validateForm()}
                    onClick={() => {
                      setBtnLoading(true);
                      submitForm();
                    }}
                  >
                    {btnLoading ? "Odesílání rezervace" : "Rezervovat"}
                  </Button>
                  <br />
                  <AddPickupPlaceModal />
                  <br />
                  <br />
                </div>
              </Container>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default App;
