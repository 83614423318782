import { AppBar, LinearProgress, Typography, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Header.css";
import { webStructureService } from "../Utils/ApiService";

interface ITopNotification {
  text?: string;
  color?: "success" | "info" | "warning" | "error";
  validFrom?: Date;
  validTo?: Date;
}

function Header() {
  const [notification, setNotification] = useState<ITopNotification>({});

  useEffect(() => {
    webStructureService.get(
      "/notifications/top",
      { domain: "erecept.magistrmartin.cz" },
      {
        success: (data: ITopNotification | null) => data && setNotification(data),
        error: console.log,
      }
    );
  }, []);

  return (
    <>
      {notification.text && notification.text.length > 0 && (
        <Alert variant="filled" severity={notification.color} style={{ borderRadius: 0, justifyContent: "center" }}>
          {notification.text}
        </Alert>
      )}
      <AppBar position="relative">
        <Typography variant="h3" id="headerTitle">
          <span id="appender">Magistr Martin | </span>Rezervace eReceptu
        </Typography>
        <LinearProgress variant="determinate" value={100} color="secondary" />
      </AppBar>
      <br />
      <br />
      <Alert severity="success" style={{ width: "80%", marginLeft: "10%" }}>
        2x denně již do lékárny nemusíte. Jděte do lékárny pro své léky s jistotou! Vyplňte pohodlně jednoduchý formulář. Na
        výběr máte kromě Magistr Martin lékáren i z dalších spolupracujících lékáren z řad nezávislých rodinných lékáren.
        Podpořte svého lékárníka. Děkujeme.
      </Alert>
      <br />
    </>
  );
}

export default Header;
