import React, { useEffect, useState } from "react";
import { personalData, PickupPlace } from "../types";
import { ordersService } from "../Utils/ApiService";
import { TextField, Typography, Autocomplete, TableRow, Table, TableBody, TableCell, Button } from "@mui/material";
import { Box } from "@mui/system";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { formatters } from "../Utils/Formaters";
import PlaceSelectMap from "./PlaceSelectMap";

interface IProps {
  personalData: personalData;
  setPersonalData: (data: personalData) => void;
}

export default function ({ personalData, setPersonalData }: IProps) {
  const [pickupPlaces, setPickupPlaces] = useState<PickupPlace[]>([]);

  useEffect(() => {
    ordersService.get(
      "/infrastructure/prescriptionPlaces",
      {},
      {
        success: setPickupPlaces,
        error: console.log,
      }
    );
  }, []);

  return (
    <>
      <br />
      <Typography variant="h5" align="left">
        Moje údaje
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        label="Jméno a příjmení"
        value={personalData.name}
        onChange={(e) => setPersonalData({ ...personalData, name: e.target.value })}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Email"
        value={personalData.email}
        onChange={(e) => setPersonalData({ ...personalData, email: e.target.value })}
      />
      <PhoneInput
        value={personalData.phone}
        onChange={(val) => setPersonalData({ ...personalData, phone: formatters.PhoneFormater(val) })}
        country="cz"
        preferredCountries={["cz"]}
        placeholder="+420 000 000 000"
        inputStyle={{ width: "100%" }}
        containerStyle={{ paddingBottom: 12, paddingTop: 6 }}
        specialLabel="Telefon"
      />
      <Autocomplete
        value={{
          label: pickupPlaces.filter((p) => p.id === personalData.place)[0]?.name || "",
          id: personalData.place || -1,
          obj: pickupPlaces.filter((p) => p.id === personalData.place)[0],
        }}
        onChange={(event, newValue) => {
          setPersonalData({ ...personalData, place: newValue?.id === undefined ? -1 : newValue.id });
        }}
        disablePortal
        options={[
          ...pickupPlaces.map((p) => {
            return { label: `${p.name} ${p.street} ${p.houseNum} ${p.zip} ${p.city}`, id: p.id, obj: p };
          }),
          { label: "", id: -1, obj: null },
        ]}
        isOptionEqualToValue={(op, val) => op.id === val.id}
        renderInput={(params) => <TextField {...params} label="Vyberte lékárnu" />}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography>{option.obj?.name}</Typography>
            <Typography style={{ color: "grey" }} variant="caption">
              {option.obj?.street} {option.obj?.houseNum}, {option.obj?.zip} {option.obj?.city}
            </Typography>
          </Box>
        )}
        fullWidth
        disableClearable
      />
      {
        <div style={{ width: "100%", textAlign: "right" }}>
          <PlaceSelectMap
            //@ts-ignore
            places={pickupPlaces}
            //@ts-ignore
            onSelect={(newValue) => setPersonalData({ ...personalData, place: newValue?.id === undefined ? -1 : newValue.id })}
            //@ts-ignore
            renderOpener={(open) => <Button onClick={open}>Vybrat z mapy</Button>}
          />
        </div>
      }
      <br />
      {personalData.place > 0 && (
        <>
          <Typography textAlign="left" variant="h6" style={{ width: "100%" }}>
            Adresa vybrané lékárny
          </Typography>
          <br />
          <Typography textAlign="left" style={{ width: "100%" }}>
            {pickupPlaces.filter((p) => p.id === personalData.place)[0]?.street} {pickupPlaces.filter((p) => p.id === personalData.place)[0]?.houseNum},{" "}
            {pickupPlaces.filter((p) => p.id === personalData.place)[0]?.zip} {pickupPlaces.filter((p) => p.id === personalData.place)[0]?.city}
          </Typography>
          <br />
          <Typography textAlign="left" variant="h6" style={{ width: "100%" }}>
            Otevírací doba vybrané lékárny
          </Typography>
          <Table size="small">
            <TableBody>
              {pickupPlaces
                .filter((p) => p.id === personalData.place)[0]
                ?.nextTenOpenningDays.map((oh) => (
                  <TableRow key={oh.id}>
                    <TableCell style={{ fontWeight: oh.id === 0 ? "bold" : "normal" }}>{(oh?.dayName || "").split("\t")[0]}</TableCell>
                    <TableCell style={{ fontWeight: oh.id === 0 ? "bold" : "normal" }}>{(oh?.dayName || "\t").split("\t")[1]}</TableCell>
                    <TableCell style={{ fontWeight: oh.id === 0 ? "bold" : "normal" }}>{oh.value || "Zavřeno"}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <br />
          <Typography variant="caption" align="justify">
            Informace poskytla uvedená lékárna. Nezodpovídáme za aktualitu dat, ale snažíme se je pravidelně kontrolovat. Děkujeme za pochopení.
          </Typography>
        </>
      )}
    </>
  );
}
